.table-header__order {
  cursor: pointer !important;
}

.table-header__order:hover,
.table-header__order.active {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.dateValidation.false input,
.dateValidation input:invalid {
  border: 1px solid red !important;
}

#company-profile-submenu .menu > .item > .item:has(.active.item) {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.95);
}

.create-company-custom-search .search {
  padding-left: 40px !important;
}